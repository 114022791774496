import React from "react";
import { defaultIconProps } from "~/constants/icon";
import { IconProps } from "~/model/Icon";

export const ValidationIcon: React.FC<IconProps> = ({
  size = defaultIconProps.size,
  fill = defaultIconProps.fill,
}) => (
  <svg
    style={{ height: size, width: size }}
    viewBox="0 0 89 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.082 48.0205H18.4697V40.6328H11.082V48.0205ZM11.082 62.796H18.4697V55.4082H11.082V62.796ZM11.082 33.2451H18.4697V25.8574H11.082V33.2451ZM25.8574 48.0205H77.5714V40.6328H25.8574V48.0205ZM25.8574 62.796H77.5714V55.4082H25.8574V62.796ZM25.8574 25.8574V33.2451H77.5714V25.8574H25.8574ZM11.082 48.0205H18.4697V40.6328H11.082V48.0205ZM11.082 62.796H18.4697V55.4082H11.082V62.796ZM11.082 33.2451H18.4697V25.8574H11.082V33.2451ZM25.8574 48.0205H77.5714V40.6328H25.8574V48.0205ZM25.8574 62.796H77.5714V55.4082H25.8574V62.796ZM25.8574 25.8574V33.2451H77.5714V25.8574H25.8574Z"
      fill={fill}
    />
  </svg>
);
