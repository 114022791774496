import React, { createContext, useEffect } from "react";
import { useState } from "react";

interface IMobileMenuContext {
  open: boolean;
  toggleMenu: () => void;
}

export const MobileMenuContext = createContext<IMobileMenuContext>({
  open: false,
  toggleMenu: () => null,
});

export const MobileMenuContextProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log("open changed", open);
  }, [open]);

  const toggleMenu = () => setOpen((prevOpenState) => !prevOpenState);

  return (
    <MobileMenuContext.Provider value={{ open, toggleMenu }}>
      {children}
    </MobileMenuContext.Provider>
  );
};
