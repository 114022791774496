import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { CloseIcon } from "./icons";
import { Colors } from "~/constants/colors";
import { headerRoutes } from "~/constants/headerRoutes";

interface Props {
  open: boolean;
  toggleMenu: () => void;
}

export const SiteMobileNav: React.FC<Props> = ({ open, toggleMenu }) => {
  const imgQuery = useStaticQuery<{
    file: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
  }>(graphql`
    query {
      file(absolutePath: { regex: "/images/brand/brand-light.png/g" }) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            placeholder: DOMINANT_COLOR
            formats: [PNG, AVIF]
          )
        }
      }
    }
  `);

  // This prevents background scrolling whenever the menu is open
  useEffect(() => {
    if (open) {
      document.body.style.position = "fixed";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.position = "initial";
      document.body.style.overflowY = "initial";
    }
  }, [open]);

  return open ? (
    <Container show={open}>
      <HeaderContainer>
        <Header>Menu</Header>
        <button onClick={() => toggleMenu()}>
          <CloseIcon size={36} fill={Colors.White} />
        </button>
      </HeaderContainer>
      <NavigationListContainer>
        {headerRoutes
          .filter((route) => route.enabled)
          .map((route) => {
            return (
              <NavItem
                key={route.id}
                to={route.href}
                onClick={() => toggleMenu()}
              >
                {route.name}
              </NavItem>
            );
          })}
      </NavigationListContainer>
      <BottomLogoContainer>
        <GatsbyImage
          alt="logo"
          image={imgQuery.file.childImageSharp.gatsbyImageData}
        />
      </BottomLogoContainer>
    </Container>
  ) : (
    <EmptyContainer />
  );
};

const animationKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const EmptyContainer = styled.div`
  display: none;
`;

const Container = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.GreyDark};
  animation: ${animationKeyframes} 0.15s ease-in;
  padding: 2.5rem;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    background: none;
    border: none;
  }
`;

const Header = styled.h1`
  color: ${Colors.OffWhite};
  font-size: 2.5rem;
  font-weight: 500;
`;

const NavigationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const NavItem = styled(Link)`
  color: ${Colors.White};
  text-decoration: none;
  font-size: 2rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${Colors.Transparent};
  transition: border-bottom 0.15s ease-in;

  :hover {
    border-bottom-color: ${Colors.White};
  }
`;

const BottomLogoContainer = styled.div`
  position: fixed;
  bottom: 2.5rem;
  left: 2.5rem;
`;
