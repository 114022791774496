import { Route } from "~/model/Navigation";

const _routes: Route[] = [
  {
    id: "home",
    name: "Home",
    href: "/",
    enabled: true,
  },
  {
    id: "about",
    name: "About Us",
    href: "/about",
    enabled: true,
  },
  {
    id: "talent-by-specialty",
    name: "Talent by Specialty",
    href: "/talent-by-specialty",
    enabled: true,
  },
  {
    id: "talent-by-profession",
    name: "Talent by Profession",
    href: "/talent-by-profession",
    enabled: true,
  },
  {
    id: "contact-us",
    name: "Contact Us",
    href: "/contact-us",
    enabled: true,
  },
];

export const headerRoutes = _routes.filter((route) => route.enabled);
