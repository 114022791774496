import React from "react";
import { defaultIconProps } from "~/constants/icon";
import { IconProps } from "~/model/Icon";

export const AdvancedServicesIcon: React.FC<IconProps> = ({
  size = defaultIconProps.size,
  fill = defaultIconProps.fill,
}) => (
  <svg
    style={{ height: size, width: size }}
    viewBox="0 0 89 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.0464 67.1185L60.469 47.541H56.8121L47.4297 56.9234V60.5803L67.0071 80.1578C68.4477 81.5984 70.7748 81.5984 72.2154 80.1578L80.0464 72.3268C81.487 70.9231 81.487 68.5591 80.0464 67.1185ZM69.5928 72.3637L53.9309 56.7018L56.5535 54.0791L72.2154 69.7411L69.5928 72.3637Z"
      fill={fill}
    />
    <path
      d="M64.0524 37.6408L69.2607 32.4325L77.0917 40.2635C81.4135 35.9417 81.4135 28.9234 77.0917 24.6015L64.0154 11.5253L58.8071 16.7336V6.31697L56.2214 3.69434L43.1452 16.7706L45.7678 19.3932H56.2214L51.0131 24.6015L54.9286 28.517L44.2533 39.1923L28.9977 23.9367V18.6914L17.8423 7.53594L7.38867 17.9895L18.581 29.1819H23.7894L39.045 44.4375L35.9052 47.5773H28.0742L8.49683 67.1547C7.05622 68.5953 7.05622 70.9225 8.49683 72.3631L16.3278 80.194C17.7684 81.6346 20.0955 81.6346 21.5361 80.194L41.1135 60.6166V52.7857L60.1369 33.7623L64.0524 37.6408ZM34.5754 56.6642L18.9135 72.3261L16.2909 69.7035L31.9528 54.0416L34.5754 56.6642Z"
      fill={fill}
    />
  </svg>
);
