import React from "react";
import { defaultIconProps } from "~/constants/icon";
import { IconProps } from "~/model/Icon";

export const RequirementsIcon: React.FC<IconProps> = ({
  size = defaultIconProps.size,
  fill = defaultIconProps.fill,
}) => (
  <svg
    style={{ height: size, width: size }}
    viewBox="0 0 89 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.082 36.9385H51.7144V44.3262H11.082V36.9385ZM11.082 29.5508H51.7144V22.1631H11.082V29.5508ZM11.082 59.1016H36.939V51.7139H11.082V59.1016ZM66.5268 47.5399L69.1494 44.9172C70.59 43.4766 72.9171 43.4766 74.3577 44.9172L76.9804 47.5399C78.421 48.9805 78.421 51.3076 76.9804 52.7482L74.3577 55.3708L66.5268 47.5399ZM63.9041 50.1625L44.3267 69.7399V77.5709H52.1577L71.7351 57.9935L63.9041 50.1625Z"
      fill={fill}
    />
  </svg>
);
