import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { extractValues } from "~/helpers/extractContent";

interface Query {
  seoQuery: {
    nodes: {
      seo_info_title: string;
      seo_info_description: string;
    }[];
  };
}

export const SiteHelmet = () => {
  const { seoQuery } = useStaticQuery<Query>(graphql`
    query {
      seoQuery: allSeoJson {
        nodes {
          seo_info_description
          seo_info_title
        }
      }
    }
  `);

  const content = extractValues<{
    seo_info_title: string;
    seo_info_description: string;
  }>(seoQuery.nodes);

  const title = content?.seo_info_title ?? "";
  const description = content?.seo_info_description ?? "";
  const url = "https://cloud-talent.com";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Social */}
      {/* TODO: Add social images */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};
