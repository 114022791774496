export enum Colors {
  BrandRed = "#920000",
  BrandYellow = "#ffc209",
  White = "#ffffff",
  Black = "#000000",
  GreyDark = "#303030",
  Grey = "#333",
  FooterBgGrey = "#343a41",
  OffWhite = "gainsboro",
  Overlay = "rgba(0, 0, 0, 0.85)",
  Shadow = "rgba(0, 0, 0, 0.75)",
  HeaderLinkBg = "rgba(0, 0, 0, 0.25)",
  Transparent = "rgba(0, 0, 0, 0)",
  SubtleGrey = "#ccc",
  BrandOrange = "#c65a12",
  BrandBlue = "#2980b9",
  ErrorRed = "rgba(231, 76, 60, 0.85)",
  SuccessGreen = "rgba(39, 174, 96, 0.85)",
}

export enum IconColors {
  RequirementsIcon = "#68b5bb",
  VideoScreenIcon = "#72a621",
  ValidationIcon = "#72a621",
  TalentManagementIcon = "#15588e",
  AdvServicesIcon = "#e77d14",
}
