// New extractor for content

function extractDefinedValues<T extends object>(valueObj: T): Partial<T> {
  const keys = Object.keys(valueObj);
  return keys.reduce((acc, key: string) => {
    if (!!valueObj[key as keyof typeof valueObj]) {
      return {
        ...acc,
        [key]: valueObj[key as keyof typeof valueObj],
      };
    }
    return acc;
  }, {});
}

export function extractValues<T extends object>(data: T[]): Partial<T> {
  return data.reduce((acc, item) => {
    return {
      ...acc,
      ...extractDefinedValues(item),
    };
  });
}
