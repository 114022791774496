import React from "react";
import { defaultIconProps } from "~/constants/icon";
import { IconProps } from "~/model/Icon";

export const TalentManagementIcon: React.FC<IconProps> = ({
  size = defaultIconProps.size,
  fill = defaultIconProps.fill,
}) => (
  <svg
    style={{ height: size, width: size }}
    viewBox="0 0 89 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.6329 62.796C40.6329 64.8276 42.2951 66.4898 44.3267 66.4898C46.3583 66.4898 48.0206 64.8276 48.0206 62.796C48.0206 60.7644 46.3583 59.1021 44.3267 59.1021C42.2951 59.1021 40.6329 60.7644 40.6329 62.796ZM40.6329 11.082V25.8574H48.0206V18.7652C60.5427 20.5752 70.1837 31.2874 70.1837 44.3267C70.1837 58.6219 58.6219 70.1837 44.3267 70.1837C30.0315 70.1837 18.4697 58.6219 18.4697 44.3267C18.4697 38.121 20.6491 32.4325 24.306 27.9999L44.3267 48.0206L49.535 42.8122L24.4168 17.694V17.7679C16.3273 23.8258 11.082 33.4298 11.082 44.3267C11.082 62.6852 25.9313 77.5714 44.3267 77.5714C62.6852 77.5714 77.5714 62.6852 77.5714 44.3267C77.5714 25.9683 62.6852 11.082 44.3267 11.082H40.6329ZM66.4898 44.3267C66.4898 42.2951 64.8276 40.6329 62.796 40.6329C60.7644 40.6329 59.1021 42.2951 59.1021 44.3267C59.1021 46.3583 60.7644 48.0206 62.796 48.0206C64.8276 48.0206 66.4898 46.3583 66.4898 44.3267ZM22.1636 44.3267C22.1636 46.3583 23.8258 48.0206 25.8574 48.0206C27.8891 48.0206 29.5513 46.3583 29.5513 44.3267C29.5513 42.2951 27.8891 40.6329 25.8574 40.6329C23.8258 40.6329 22.1636 42.2951 22.1636 44.3267Z"
      fill={fill}
    />
  </svg>
);
