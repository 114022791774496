import React from "react";
import { GlobalStyle } from "./GlobalStyle";
import { SiteHelmet } from "./SiteHelmet";
import { SiteMobileNav } from "./SiteMobileNav";
import "normalize.css/normalize.css";
import {
  MobileMenuContext,
  MobileMenuContextProvider,
} from "~/context/MobileMenuContext";

// ts-prune-ignore-next
export const SiteLayout: React.FC = ({ children }) => (
  <MobileMenuContextProvider>
    <SiteHelmet />
    <GlobalStyle />
    {children}
    <MobileMenuContext.Consumer>
      {(props) => (
        <SiteMobileNav open={props.open} toggleMenu={props.toggleMenu} />
      )}
    </MobileMenuContext.Consumer>
  </MobileMenuContextProvider>
);
