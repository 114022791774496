import React from "react";
import { defaultIconProps } from "~/constants/icon";
import { IconProps } from "~/model/Icon";

export const CloseIcon: React.FC<IconProps> = ({
  size = defaultIconProps.size,
  fill = defaultIconProps.fill,
}) => (
  <svg
    style={{ height: size, width: size }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke={fill}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);
